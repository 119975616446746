import { useQuery } from "react-query";
// @ts-ignore
import { COUNTRIES_VALUES } from "@retail/i18n/constants/countries";

export const useCountries = () => {
  return useQuery(
    "@@countries",
    async () =>
      Array.from<string>(COUNTRIES_VALUES)
        .sort()
        .map((country) => ({
          value: country,
          title: country,
        })),
    { refetchOnWindowFocus: false }
  );
};
