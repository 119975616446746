import { createGqlCall } from "@src/apiClient";
import { useQuery } from "react-query";

const userPermissionsQuery = `query{
    me {
        id
        effectivePermissions
    }
}`;

type FetchUserPermissionsResponse = {
  data: {
    me: {
      effectivePermissions: string[];
    };
  };
};

const fetchUserPermissions =
  createGqlCall<FetchUserPermissionsResponse>(userPermissionsQuery);

export const useUserPermissions = () => {
  return useQuery(
    ["fetchUserPermissions"],
    () =>
      fetchUserPermissions().then((res) => res.data.me.effectivePermissions),
    { refetchOnWindowFocus: false, enabled: false }
  );
};
