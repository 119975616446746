import { createGqlCall } from "@src/apiClient";

const QUERY = `query SearchRetailFeatureChangelogs($id: UUID!, $page: Int!, $pageSize: Int!) {
  searchRetailFeatureChangelogs(
    id: $id,
    search:{
        page: $page,
        pageSize: $pageSize
    }) {
        page
        pageSize
        totalPageCount
        totalEntityCount
        entities
        {
            id
            action
            field
            newValue
            createdOn
            createdBy {
                id
                email
                firstName
                lastName
            }
        }
  }
}`;

interface QueryVariables {
  id: string;
  page: number;
  pageSize: number;
}

export interface ChangelogEntry {
  id: number;
  field: "enabled" | "condition" | null;
  action: "insert" | "update";
  newValue: string;
  createdOn: string;
  createdBy: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
  } | null;
}

interface QueryResult {
  searchRetailFeatureChangelogs: {
    page: number;
    pageSize: number;
    totalPageCount: number;
    totalEntityCount: number;
    entities: ChangelogEntry[];
  };
}

export const fetchChangelog = createGqlCall<
  { data: QueryResult },
  QueryVariables
>(QUERY);
