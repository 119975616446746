import { FeatureDetailsEntity } from "@src/api";
import { createGqlCall } from "@src/apiClient";
import { updateFeatureQuery } from "./updateFeatureQuery";

type UpdateFeatureResult = {
  data: {
    name: string;
  };
};

type UpdateFeatureEntity = Pick<
  FeatureDetailsEntity,
  "condition" | "description" | "enabled"
>;

type UpdateFeatureVariables = {
  id: string;
  feature: UpdateFeatureEntity;
};

export const updateFeature = createGqlCall<
  UpdateFeatureResult,
  UpdateFeatureVariables
>(updateFeatureQuery);
