import { useMemo, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { keys } from "ramda";

export enum Status {
  All = "All",
  Enabled = "Enabled",
  Disabled = "Disabled",
}

export type FeaturesFilter = {
  name?: string;
  status: Status;
};

const getStatusValue = (status: string | null): Status => {
  switch (status) {
    case Status.Enabled:
    case Status.Disabled:
      return status;
    default:
      return Status.All;
  }
};

export const useFeaturesFilter = () => {
  const [search, setSearch] = useSearchParams();

  const name = search.get("name");
  const status = search.get("status");
  const filter: FeaturesFilter = useMemo(
    () => ({
      name: name ?? undefined,
      status: getStatusValue(status),
    }),
    [name, status]
  );

  const setFilter = useCallback(
    (newFilter: FeaturesFilter) => {
      keys(newFilter).forEach((key) => {
        const value = newFilter[key];
        value ? search.set(key, value) : search.delete(key);
      });
      setSearch(search);
    },
    [search, setSearch]
  );

  return [filter, setFilter] as const;
};
