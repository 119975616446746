import { generatePath } from "react-router";
import { Link } from "react-router-dom";
import { Table, Button, Space, Tooltip, message, Switch } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Utils } from "react-awesome-query-builder";
import { routePathByPage } from "@src/constants";
import { useLang } from "@hooks/useLang";
import { useHasPermission } from "@hooks/useHasPermission";
import { useQueryBuilderConfig } from "@hooks/useQueryBuilderConfig";
import { deleteFeature } from "@src/api/deleteFeature";
import { FeatureListItem } from "../../types";
import { useFeaturesList } from "../../api";
import { useFeaturesFilter } from "../../hooks";
import { defaultPagination, usePagination } from "../../hooks/usePagination";
import { useFeatureSwitchConfirmPopup, FeatureSwitch } from "../FeatureSwitch";

export const FeaturesTable = () => {
  const { data, isFetching, refetch: refetchFeatures } = useFeaturesList();
  const [pagination, updatePagination] = usePagination();
  const [filter] = useFeaturesFilter();
  const config = useQueryBuilderConfig();
  const lang = useLang();
  const hasPermission = useHasPermission();
  const switchPopup = useFeatureSwitchConfirmPopup({
    onUpdate: refetchFeatures,
  });

  const handleDeleteFeature = (id: string) => async () => {
    if (!confirm("Are you sure you want to delete feature?")) {
      return;
    }

    await deleteFeature({ id });
    message.success("Feature was successfully deleted");
    await refetchFeatures();
  };

  return (
    <Table
      style={{ wordBreak: "break-word" }}
      loading={isFetching}
      rowKey="id"
      dataSource={data?.entities}
      onChange={(newPagination) =>
        updatePagination({
          page: newPagination.current ?? defaultPagination.page,
          pageSize: newPagination.pageSize ?? defaultPagination.pageSize,
        })
      }
      pagination={{
        total: data?.totalEntityCount,
        pageSize: pagination.pageSize,
        current: pagination.page,
      }}
    >
      <Table.Column width="25%" title="Name" dataIndex="name" key="name" />
      <Table.Column
        width="30%"
        title="Description"
        dataIndex="description"
        key="description"
      />
      <Table.Column
        width="30%"
        title="Condition"
        dataIndex="condition"
        key="condition"
        render={(condition) =>
          Utils.queryString(
            Utils.loadFromJsonLogic(condition, config),
            config,
            true
          )
        }
      />
      <Table.Column<FeatureListItem>
        width="10%"
        title="Status"
        dataIndex="enabled"
        key="enabled"
        render={(isEnabled: boolean, { id }) =>
          hasPermission("editFeature") ? (
            <FeatureSwitch
              isVisible={switchPopup.forId === id}
              status={switchPopup.status}
              onSwitch={() => switchPopup.onRequest(id, !isEnabled)}
              onConfirm={switchPopup.onConfirm}
              onCancel={switchPopup.onReset}
            >
              <Switch checked={isEnabled} data-qa-id="switch_status" />
            </FeatureSwitch>
          ) : isEnabled ? (
            "ENABLED"
          ) : (
            "DISABLED"
          )
        }
      />
      <Table.Column<FeatureListItem>
        title="Action"
        key="action"
        fixed="right"
        dataIndex="id"
        render={(id, row) => (
          <Space>
            {hasPermission("editFeature") && (
              <Link
                to={generatePath(routePathByPage.edit, { id, lang })}
                state={filter}
              >
                <Button
                  icon={<EditOutlined />}
                  type="primary"
                  data-qa-id="button_edit"
                />
              </Link>
            )}
            {hasPermission("deleteFeature") &&
              (row.enabled ? (
                <Tooltip
                  title="You can't delete an active feature"
                  placement="topLeft"
                >
                  <Button
                    icon={<DeleteOutlined />}
                    type="primary"
                    disabled
                    data-qa-id="button_delete"
                  />
                </Tooltip>
              ) : (
                <Button
                  icon={<DeleteOutlined style={{ color: "white" }} />}
                  type="ghost"
                  style={{ background: "black" }}
                  onClick={handleDeleteFeature(id)}
                  data-qa-id="button_delete"
                />
              ))}
          </Space>
        )}
      />
    </Table>
  );
};
