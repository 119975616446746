import { equals, pick } from "ramda";
import { Config, Utils, ImmutableTree } from "react-awesome-query-builder";
import { FeatureDetailsVM } from "@src/pages/FeatureDetails/FeatureDetails";

const FEATURE_EDITABLE_FIELDS = [
  "condition",
  "enabled",
  "description",
] as const;

type UnboxingArray<T> = T extends ReadonlyArray<infer Member> ? Member : T;

type PartialFeature = Partial<
  Pick<FeatureDetailsVM, UnboxingArray<typeof FEATURE_EDITABLE_FIELDS>>
>;

export const isFeatureDetailsEqual = (
  feature1: PartialFeature,
  feature2: PartialFeature
) => {
  const pickFields = pick(FEATURE_EDITABLE_FIELDS);

  return equals(pickFields(feature1), pickFields(feature2));
};

const emptyJsonLogicRule = { and: [] };

export const parseCondition = (
  input: null | undefined | {},
  config: Config
): ImmutableTree =>
  Utils.loadFromJsonLogic(input ?? emptyJsonLogicRule, config);

export const serializeCondition = (
  input: ImmutableTree,
  config: Config
): null | {} => {
  const { errors, logic } = Utils.jsonLogicFormat(input, config);

  if (errors?.length) {
    throw new Error(`Invalid condition value: ${errors.join(", ")}`);
  }

  if (!logic) {
    return null;
  }

  const conditionTree = logic as Record<string, {}[]>;
  const rootCondition = conditionTree.and ?? conditionTree.or;

  return rootCondition?.length === 1 && Object.keys(conditionTree).length === 1
    ? rootCondition[0]
    : logic;
};
