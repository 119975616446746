import { useNavigateToFeaturesOverview } from "@src/hooks";
import React from "react";
import { Button, Col, PageHeader, Row } from "antd";

interface FeatureDetailsLayoutProps {
  title: string;
  children: React.ReactNode;
}

export const FeatureDetailsLayout = ({
  title,
  children,
}: FeatureDetailsLayoutProps) => {
  const navigateToFeaturesOverview = useNavigateToFeaturesOverview();
  return (
    <>
      <PageHeader
        title={title}
        extra={
          <Button
            onClick={navigateToFeaturesOverview}
            size="large"
            data-qa-id="button_go_to_list"
          >
            Go to List
          </Button>
        }
      />
      <Row justify="center" style={{ padding: "12px 5vw 24px 5vw" }}>
        <Col xs={24} xl={18}>
          {children}
        </Col>
      </Row>
    </>
  );
};
