import { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { UpdateStatus } from "./types";
import { changeFeatureStatus } from "./api";

interface RequestedChange {
  forId: string;
  toState: boolean;
}

interface UseConfirmPopupInput {
  onUpdate: () => void;
}

const CLOSE_AFTER = 1500;

export const useConfirmPopup = ({ onUpdate }: UseConfirmPopupInput) => {
  const [requested, setRequested] = useState<null | RequestedChange>(null);
  const mutation = useMutation(changeFeatureStatus, {
    onSuccess: onUpdate,
  });

  const progressStatus = (
    {
      idle: null,
      loading: "IN_PROGRESS",
      error: "FAILURE",
      success: "SUCCESS",
    } as const
  )[mutation.status];
  const status: null | UpdateStatus =
    progressStatus ?? (requested && "CONFIRMATION");

  const onRequest = (forId: string, toState: boolean) => {
    setRequested({
      forId,
      toState,
    });
  };

  const onConfirm = () => {
    const { forId, toState } = requested!;

    mutation.mutate({
      id: forId,
      isEnabled: toState,
    });
  };

  const onReset = () => {
    setRequested(null);
    mutation.reset();
  };

  useEffect(() => {
    if (requested && !mutation.isLoading && mutation.isSuccess) {
      const timerId = setTimeout(onReset, CLOSE_AFTER);

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [mutation.isLoading, mutation.isSuccess, requested]); // eslint-disable-line

  return {
    forId: requested?.forId,
    status,
    onRequest,
    onConfirm,
    onReset,
  };
};
