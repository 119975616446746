import { createGqlCall } from "@src/apiClient";
import { CHANGE_FEATURE_STATUS } from "./changeStatusMutation";

interface ChangeFeatureStatusVars {
  id: string;
  isEnabled: boolean;
}

export const changeFeatureStatus = createGqlCall<
  unknown,
  ChangeFeatureStatusVars
>(CHANGE_FEATURE_STATUS);
