import { useMemo, useCallback } from "react";
import { useSearchParams } from "react-router-dom";

export type Pagination = {
  pageSize: number;
  page: number;
};

export const defaultPagination: Pagination = {
  page: 1,
  pageSize: 10,
};

export const usePagination = () => {
  const [search, setSearch] = useSearchParams();

  const pagination: Pagination = useMemo(
    () => ({
      page: +(search.get("page") ?? defaultPagination.page),
      pageSize: +(search.get("pageSize") ?? defaultPagination.pageSize),
    }),
    [search]
  );

  const updatePagination = useCallback(
    (pagination: Pagination) => {
      search.set("page", pagination.page.toString());
      search.set("pageSize", pagination.pageSize.toString());
      setSearch(search);
    },
    [search, setSearch]
  );

  return [pagination, updatePagination] as const;
};
