import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import isValid from "date-fns/isValid";

export function formatDate(isoDateTime: string): string {
  const parsed = parseISO(isoDateTime);
  if (!isValid(parsed)) {
    return "";
  }
  return format(parsed, "dd/MM/yyyy HH:mm");
}
