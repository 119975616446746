export const featureDetailsQuery = `query($id: UUID!) {
    retailFeature(id: $id) {
        condition
        description
        enabled
        id
        name
        environment
        createdOn
        createdByUser {
          email
        }
    }
}`;
