import { generatePath } from "react-router";
import { Link } from "react-router-dom";
import { Space, Row, Col, Typography, Pagination, Button } from "antd";
import { useHasPermission } from "@hooks/useHasPermission";
import { useLang } from "@hooks/useLang";
import { routePathByPage } from "@src/constants";
import { useFeaturesList } from "./api";
import { FeaturesFilter, FeaturesTable } from "./components";
import { useFeaturesFilter, usePagination } from "./hooks";

export const FeaturesOverview = () => {
  const lang = useLang();
  const hasPermission = useHasPermission();
  const { data } = useFeaturesList();
  const [pagination, setPagination] = usePagination();
  const [filter] = useFeaturesFilter();

  return (
    <Space
      direction="vertical"
      size="large"
      style={{ padding: 20, maxWidth: "100%", width: "100%" }}
    >
      <Row align="middle" justify="space-between" wrap>
        <Col sm={18} xs={24}>
          <Typography.Title data-qa-id="feature_overview_title">
            Features Overview
          </Typography.Title>
        </Col>
        <Col>
          {hasPermission("createFeature") && (
            <Link
              to={generatePath(routePathByPage.create, { lang })}
              state={filter}
            >
              <Button
                size="large"
                type="primary"
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                data-qa-id="button_create_feature"
              >
                Create Feature
              </Button>
            </Link>
          )}
        </Col>
      </Row>
      <FeaturesFilter />
      <Row align="middle" justify="space-between">
        <Col>
          <Typography.Title level={4}>
            {data?.totalEntityCount} total results
          </Typography.Title>
        </Col>
        <Col>
          <Pagination
            current={pagination.page}
            total={data?.totalEntityCount}
            pageSize={pagination.pageSize}
            hideOnSinglePage
            onChange={(page, pageSize) => setPagination({ pageSize, page })}
          />
        </Col>
      </Row>
      <Row>
        <Col flex={1} style={{ maxWidth: "100%", overflow: "auto" }}>
          <FeaturesTable />
        </Col>
      </Row>
    </Space>
  );
};
