import { useQuery } from "react-query";
import { useParams } from "react-router";
import { Env } from "@src/config";
import { createGqlCall } from "@src/apiClient";
import { featureDetailsQuery } from "./featureDetailsQuery";

export type FeatureDetailsEntity = {
  id: string;
  name: string;
  description?: string;
  enabled: boolean;
  environment: Env;
  createdOn: string;
  createdByUser: {
    email: string;
  };
  condition: null | {};
};

type FetchFeatureDetailsResponse = {
  data: {
    retailFeature: FeatureDetailsEntity;
  };
};

const fetchFeatureDetails = createGqlCall<
  FetchFeatureDetailsResponse,
  { id: string }
>(featureDetailsQuery);

export const useFeatureDetails = () => {
  const { id } = useParams();

  return useQuery(
    ["fetchFeatureDetails", id],
    () =>
      id
        ? fetchFeatureDetails({ id }).then((res) => res.data.retailFeature)
        : undefined,
    { refetchOnWindowFocus: false }
  );
};
