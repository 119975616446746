import { FeatureDetailsEntity } from "@src/api";
import { createGqlCall } from "@src/apiClient";
import { createFeatureQuery } from "./createFeatureQuery";

type CreateFeatureEntity = Pick<
  FeatureDetailsEntity,
  "name" | "description" | "condition"
> & {
  environment: string;
};

type CreateFeatureVariables = {
  feature: CreateFeatureEntity;
};

export const createFeature = createGqlCall<unknown, CreateFeatureVariables>(
  createFeatureQuery
);
