import { useMemo } from "react";
import { Config } from "react-awesome-query-builder";
import AntdConfig from "react-awesome-query-builder/lib/config/antd";
import { ConditionField } from "@src/constants";
import { useCountries } from "./useCountries";
import { useLocales } from "./useLocales";

export type Option = {
  value: string;
  title: string;
};

const TIME_FORMAT = "HH:mm:ss";
const ISO_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";

const getQueryBuilderConfig = (
  locales: Option[],
  countries: Option[]
): Config => ({
  ...AntdConfig,
  settings: {
    ...AntdConfig.settings,
    showNot: false,
  },
  operators: {
    ...AntdConfig.operators,
    greater: {
      ...AntdConfig.operators.greater,
      labelForFormat: "after",
      label: "after",
      jsonLogic: "after",
    },
    select_any_in: {
      ...AntdConfig.operators.select_any_in,
      // based on default config
      // https://github.com/ukrbublik/react-awesome-query-builder/blob/cebc38aeb3a83ad4fbf8df94483f615b1aa4face/modules/config/basic.js#L379-L384
      formatOp: (
        field,
        op,
        values,
        valueSrc,
        valueType,
        opDef,
        operatorOptions,
        isForDisplay
      ) => {
        const val = typeof values === "string" ? [values] : values;
        if (valueSrc == "value") return `${field} in (${val.join(", ")})`;
        else return `${field} in (${values})`;
      },
    },
    equal: {
      ...AntdConfig.operators.equal,
      valueSources: ["value"],
      label: "=",
      jsonLogic: "equals",
    },
  },
  fields: {
    [ConditionField.Locale]: {
      label: "Locale",
      type: "select",
      valueSources: ["value"],
      fieldSettings: {
        listValues: locales,
      },
      operators: ["select_any_in", "equal"],
      defaultOperator: "select_any_in",
      widgets: {
        select: {
          operators: ["equal"],
        },
      },
    },
    [ConditionField.Country]: {
      label: "Country",
      type: "select",
      valueSources: ["value"],
      fieldSettings: {
        listValues: countries,
      },
      operators: ["equal", "select_any_in"],
      defaultOperator: "select_any_in",
      widgets: {
        select: {
          operators: ["equal"],
        },
      },
    },
    [ConditionField.StartDate]: {
      label: "Start Date",
      type: "datetime",
      valueSources: ["value", "func"],
      operators: ["greater"],
      fieldSettings: {
        timeFormat: TIME_FORMAT,
        valueFormat: ISO_DATE_FORMAT,
      },
    },
  },
  widgets: {
    ...AntdConfig.widgets,
    multiselect: {
      ...AntdConfig.widgets.select,
      factory: (props) => {
        // @ts-ignore
        return AntdConfig.widgets.multiselect.factory({
          ...props,
          customProps: {
            ...props?.customProps,
            dropdownClassName: `QA_ID_${props?.field}`,
          },
        });
      },
    },
    datetime: {
      ...AntdConfig.widgets.datetime,
      timeFormat: "HH:mm",
      dateFormat: "DD.MM.YYYY",
      valueFormat: "YYYY-MM-DD HH:mm:ss",
    },
  },
});
export const useQueryBuilderConfig = () => {
  const locales = useLocales();
  const countries = useCountries();

  return useMemo(
    () => getQueryBuilderConfig(locales.data ?? [], countries.data ?? []),
    [locales, countries]
  );
};
