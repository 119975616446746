import { useQuery } from "react-query";
// @ts-ignore
import { LOCALES_VALUES } from "@retail/i18n/constants/locales";

export const useLocales = () => {
  return useQuery(
    "@@locales",
    async () =>
      Array.from<string>(LOCALES_VALUES)
        .sort()
        .map((locale) => ({
          value: locale,
          title: locale,
        })),
    { refetchOnWindowFocus: false }
  );
};
