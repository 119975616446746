import defaultConfig from "../../envs/default.json";
import qaConfig from "../../envs/qa.json";
import preprodConfig from "../../envs/preprod.json";
import prodConfig from "../../envs/prod.json";
import { Config, Env } from "./types";

const envMap: Record<Env, Config> = {
  [Env.DEFAULT]: defaultConfig as Config,
  [Env.QA]: qaConfig as Config,
  [Env.PREPROD]: preprodConfig as Config,
  [Env.PROD]: prodConfig as Config,
};

const getEnv = (): Env => {
  const env =
    (typeof __env !== "undefined" && __env.REACT_APP_ENV) || "default";

  if (!Object.values(Env).includes(env as Env)) {
    throw new Error('Invalid "__env.REACT_APP_ENV" variable provided');
  }

  return env as Env;
};

export * from "./types";
export const config = envMap[getEnv()];
