export type ParsedGqlError = {
  fieldErrors: string[];
  globalErrors: string[];
};

export const parseGqlError = (err: string): string[] | string => {
  try {
    const parsedError: ParsedGqlError = JSON.parse(err);
    return [
      ...(parsedError.fieldErrors ?? []),
      ...(parsedError.globalErrors ?? []),
    ];
  } catch (error) {
    return err;
  }
};
