export const updateFeatureQuery = `mutation($id: UUID!, $feature: UpdateRetailFeatureProjectionInput!) {
    updateRetailFeature(feature: $feature, id: $id) {
        condition
        description
        enabled
        environment
        id
        name
        updatedBy
        updatedOn
    }
}`;
