import { FeatureListItem } from "../../types";

export enum Operator {
  Like = "LIKE",
  Equal = "EQ",
}

type FilterItem<
  TField = string,
  TOp extends Operator = Operator,
  TVal = string
> = {
  op: TOp;
  value: TVal;
  field: TField;
};

export type EnvironmentFilterItem = FilterItem<"environment", Operator.Equal>;
export type NameFilterItem = FilterItem<"name", Operator.Like, `%${string}%`>;
export type StatusFilterItem = FilterItem<"enabled", Operator.Equal, boolean>;

export type Filter =
  | EnvironmentFilterItem
  | {
      op: "AND";
      value: (EnvironmentFilterItem | NameFilterItem | StatusFilterItem)[];
    };

export type FetchFeaturesListResponse = {
  data: {
    searchRetailFeatures?: {
      entities: FeatureListItem[];
      totalEntityCount: number;
    };
  };
};

export type FetchFeaturesListVariables = {
  search: {
    page?: number;
    pageSize?: number;
    filter: Filter;
    sorts: [{ property: "updatedOn"; direction: "DESC" }];
  };
};
