import { useHasPermission } from "@hooks/useHasPermission";
import { Changelog } from "@src/pages/EditFeature/components/Changelog";
import { updateFeature } from "./api";
import {
  FeatureDetails,
  FeatureDetailsLayout,
  FeatureDetailsVM,
} from "../FeatureDetails";
import { useParams } from "react-router";
import { useCallback } from "react";
import { map, pick } from "ramda";
import { message } from "antd";
import { useFeatureDetails } from "@src/api";
import { useNavigateToFeaturesOverview } from "@src/hooks";

const pickFields = pick(["description", "enabled", "condition"]);

export const EditFeature = () => {
  const { data: feature, isLoading } = useFeatureDetails();
  const navigateToFeaturesOverview = useNavigateToFeaturesOverview();
  const { id } = useParams();
  const hasPermission = useHasPermission();

  const handleSubmit = useCallback(
    async (feature: FeatureDetailsVM) =>
      id
        ? updateFeature({ id, feature: pickFields(feature) })
            .then(navigateToFeaturesOverview)
            .then(() => message.success("Feature was successfully updated"))
            .catch(map(message.error))
        : undefined,
    [id, navigateToFeaturesOverview]
  );

  return (
    <FeatureDetailsLayout title="Edit feature">
      <FeatureDetails
        mode="edit"
        loading={isLoading}
        initialValues={feature}
        onSubmit={handleSubmit}
      />
      {id && hasPermission("viewFeatureChangelog") && (
        <Changelog featureId={id} />
      )}
    </FeatureDetailsLayout>
  );
};
