import { Space } from "antd";
import { CSSProperties } from "react";
import { Utils } from "react-awesome-query-builder";
import {
  PlayCircleTwoTone,
  PlusSquareTwoTone,
  SlidersTwoTone,
  StopTwoTone,
  WarningTwoTone,
} from "@ant-design/icons";
import { useQueryBuilderConfig } from "@src/hooks";
import { ChangelogEntry } from "@src/pages/EditFeature/api/fetchChangelog";

interface ChangeFormatterProps {
  entry: ChangelogEntry;
}

const iconStyle: CSSProperties = { fontSize: "24px" };

export const ChangeFormatter = ({
  entry: { field, newValue, action },
}: ChangeFormatterProps) => {
  if (field === "enabled") {
    return (
      <Space size="small">
        {newValue === "1" ? (
          <PlayCircleTwoTone style={iconStyle} />
        ) : (
          <StopTwoTone style={iconStyle} />
        )}
        {newValue === "1" ? "Feature enabled" : "Feature disabled"}
      </Space>
    );
  }

  if (field === "condition") {
    return <ConditionChange action={action} newValue={newValue} />;
  }

  return (
    <Space size="small">
      {field === null && action === "insert" ? (
        <>
          <PlusSquareTwoTone style={iconStyle} />
          Feature created
        </>
      ) : (
        <>
          <WarningTwoTone style={iconStyle} />
          Unknown change
        </>
      )}
    </Space>
  );
};

const ConditionChange = ({
  action,
  newValue,
}: Pick<ChangelogEntry, "action" | "newValue">) => {
  const config = useQueryBuilderConfig();
  const icon = <SlidersTwoTone style={iconStyle} />;

  if (newValue) {
    const conditionAsText = Utils.queryString(
      Utils.loadFromJsonLogic(JSON.parse(newValue), config),
      config,
      true
    );
    return (
      <Space direction="vertical">
        <Space size="small">
          {icon}
          {action === "insert"
            ? `Feature created with the condition:`
            : `Condition changed:`}
        </Space>
        {conditionAsText}
      </Space>
    );
  }

  return (
    <Space size="small">
      {icon}
      {action === "insert"
        ? "Feature created without condition"
        : "Feature condition removed"}
    </Space>
  );
};
