export enum ConditionField {
  Locale = "locale",
  Country = "country",
  StartDate = "startDate",
}

const overviewPath = "/:lang/features";

export const routePathByPage = {
  overview: overviewPath,
  edit: `${overviewPath}/:id/edit`,
  create: `${overviewPath}/create`,
};
