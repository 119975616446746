import { Button, Table } from "antd";
import { useRef, useState } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { RightCircleTwoTone } from "@ant-design/icons";
import { fetchChangelog } from "@src/pages/EditFeature/api";
import { ChangelogEntry } from "@src/pages/EditFeature/api/fetchChangelog";
import { ChangeFormatter } from "@src/pages/EditFeature/components/Changelog/ChangeFormatter";
import { formatDate } from "@src/utils";

interface ChangelogProps {
  featureId: string;
}

const PAGE_SIZE = 5;

export const Changelog = ({ featureId }: ChangelogProps) => {
  const [searchParams, setSearchParams] = useSearchParams({ page: "1" });
  const pageNumber = Number(searchParams.get("page"));
  const [isExpanded, setExpanded] = useState(false);

  const { data, isLoading, refetch, isFetching } = useQuery({
    enabled: isExpanded,
    queryKey: ["fetchFeatureChangelog", featureId, pageNumber],
    queryFn: () =>
      fetchChangelog({
        id: featureId,
        page: pageNumber - 1,
        pageSize: PAGE_SIZE,
      }).then((res) => {
        const { entities, ...rest } = res.data.searchRetailFeatureChangelogs;
        return {
          ...rest,
          entities,
        };
      }),
    keepPreviousData: true,
  });

  const tableDivRef = useRef<HTMLDivElement>(null);

  const onToggleClick = () => {
    if (isExpanded) {
      setExpanded(false);
    } else {
      refetch().then(() => {
        if (tableDivRef.current?.scrollIntoView) {
          tableDivRef.current.scrollIntoView({ behavior: "smooth" });
        }
      });
      setExpanded(true);
    }
  };

  return (
    <>
      <Button
        size="large"
        shape="round"
        style={{ marginBottom: "8px" }}
        loading={isLoading}
        icon={<RightCircleTwoTone rotate={isExpanded ? 90 : 0} />}
        onClick={onToggleClick}
      >
        Changes History
      </Button>
      {isExpanded && (
        <Table
          ref={tableDivRef}
          dataSource={data?.entities}
          loading={isFetching}
          rowKey="id"
          onChange={(pagination) => {
            const newPage = pagination.current ?? pagination.defaultCurrent;
            setSearchParams({ page: String(newPage) }, { replace: true });
          }}
          pagination={{
            current: pageNumber,
            pageSize: PAGE_SIZE,
            total: data?.totalEntityCount,
            showSizeChanger: false,
          }}
        >
          <Table.Column
            key="createdOn"
            title="Date"
            width="25%"
            render={(_, { createdOn }: ChangelogEntry) => formatDate(createdOn)}
          />
          <Table.Column
            key="user"
            title="User"
            width="30%"
            render={(_, { createdBy }: ChangelogEntry) => createdBy?.email}
          />
          <Table.Column
            key="changes"
            title="Changes"
            render={(_, entry: ChangelogEntry) => (
              <ChangeFormatter entry={entry} />
            )}
          />
        </Table>
      )}
      <div style={{ height: "400px" }} />
    </>
  );
};
