import * as t from "zod";
import { ConditionField } from "@src/constants";
import { JsonGroup, Utils } from "react-awesome-query-builder";

const findEmptyFields = (
  children: JsonGroup["children1"],
  emptyFieldsAcc = new Set<ConditionField>()
): Set<ConditionField> => {
  const list =
    children && (Array.isArray(children) ? children : Object.values(children));

  return (
    list?.reduce((acc, item) => {
      if (item.type !== "rule") {
        return findEmptyFields(item.children1 ?? {}, acc);
      }

      if (
        item.properties.field &&
        item.properties.value.some((val) => val == null)
      ) {
        acc.add(item.properties.field as ConditionField);
      }

      return acc;
    }, emptyFieldsAcc) ?? emptyFieldsAcc
  );
};

export const getValidationSchema = (mode: "create" | "edit") => {
  const nameField = t
    .string({ invalid_type_error: "Name is required" })
    .nonempty("Name is required");

  return t.object({
    name:
      mode === "create"
        ? nameField.regex(
            /^[A-Z0-9_]+$/,
            "The feature name may contain only capital letters, numbers, and underscores"
          )
        : nameField,
    description: t
      .string({ invalid_type_error: "Description is required" })
      .nonempty({ message: "Description is required" }),
    enabled: t.boolean().optional(),
    condition: t.any().superRefine((val, ctx) => {
      const emptyFields = findEmptyFields(Utils.getTree(val).children1);

      if (emptyFields.size === 0) {
        return;
      }

      const emptyFieldsList = Array.from(emptyFields, (field) => {
        const humanizedNameMap: Record<ConditionField, string> = {
          [ConditionField.Locale]: "Locale",
          [ConditionField.Country]: "Country",
          [ConditionField.StartDate]: "Start Date",
        };

        return `"${humanizedNameMap[field] ?? field}"`;
      }).join(", ");

      ctx.addIssue({
        code: t.ZodIssueCode.custom,
        message: `The following field${
          emptyFields.size > 1 ? "s are" : " is"
        } incomplete: ${emptyFieldsList}`,
      });
    }),
  });
};
