import { Card, Form, Input, Button, Select, Col, Row } from "antd";
import { useForm } from "react-hook-form";
import { SearchOutlined } from "@ant-design/icons";
import { useFeaturesFilter, Status } from "../../hooks";
import { Field } from "@components/Field";

export const FeaturesFilter = () => {
  const [filter, setFilter] = useFeaturesFilter();
  const { handleSubmit, control } = useForm({
    defaultValues: filter,
  });

  return (
    <Card title="Search for Feature" style={{ width: "100%" }}>
      <Form
        layout="vertical"
        name="features-overview-search"
        onSubmitCapture={handleSubmit(setFilter)}
      >
        <Row gutter={8} align="bottom" wrap>
          <Col md={8} xs={24}>
            <Field
              name="name"
              label="Name"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  size="large"
                  placeholder="Name"
                  data-qa-id="filter_name"
                />
              )}
            />
          </Col>
          <Col md={4} xs={24}>
            <Field
              control={control}
              name="status"
              label="Status"
              render={({ field }) => (
                <Select
                  style={{ width: "100%" }}
                  defaultValue={field.value}
                  {...field}
                  size="large"
                  placeholder="Status"
                  data-qa-id="filter_status"
                  dropdownClassName="QA_ID_dropdown_status"
                >
                  {Object.values(Status).map((status) => (
                    <Select.Option value={status} key={status}>
                      {status}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </Col>
          <Col md={4} xs={2}>
            <Form.Item>
              <Button
                icon={<SearchOutlined />}
                size="large"
                type="primary"
                htmlType="submit"
                data-qa-id="button_search"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
