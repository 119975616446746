import { useUserPermissions } from "@src/api/useUserPermissions";
import { createContext, FC } from "react";
import useMount from "react-use/lib/useMount";

export const PermissionsContext = createContext<string[]>([]);

export const PermissionsProvider: FC = ({ children }) => {
  const { data, refetch } = useUserPermissions();

  useMount(refetch);

  if (!data?.length) {
    return null;
  }

  return (
    <PermissionsContext.Provider value={data}>
      {children}
    </PermissionsContext.Provider>
  );
};
