import { useNavigateToFeaturesOverview } from "@hooks/useNavigateToFeaturesOverview";
import { config } from "@src/config";
import { message } from "antd";
import { andThen, map, otherwise, pipe, when } from "ramda";
import { DefaultValues } from "react-hook-form";
import { FeatureDetails, FeatureDetailsLayout } from "../FeatureDetails";
import { createFeature } from "./api";
import { CreateFeatureVM } from "./types";

const createFeatureInitialValues: DefaultValues<CreateFeatureVM> = {
  name: "",
  description: "",
  environment: config.FEATURE_ENV,
};

export const CreateFeature = () => {
  const navigateToFeaturesOverview = useNavigateToFeaturesOverview();
  const handleSubmit = pipe(
    (feature: CreateFeatureVM) => createFeature({ feature }),
    andThen(navigateToFeaturesOverview),
    andThen(() => message.success("Feature was successfully created")),
    otherwise(when(Array.isArray, map(message.error)))
  );

  return (
    <FeatureDetailsLayout title="Create feature">
      <FeatureDetails
        initialValues={createFeatureInitialValues}
        mode="create"
        onSubmit={handleSubmit}
      />
    </FeatureDetailsLayout>
  );
};
