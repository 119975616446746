import useUpdateEffect from "react-use/lib/useUpdateEffect";
import { useQuery } from "react-query";
import { pipe } from "ramda";
import { createGqlCall } from "@src/apiClient";
import { useFeaturesFilter, usePagination } from "../../hooks";
import { defaultPagination } from "../../hooks/usePagination";
import { fetchFeaturesListQuery } from "./fetchFeaturesListQuery";
import { toFetchFeaturesListVariables } from "./mappers";
import { FetchFeaturesListResponse, FetchFeaturesListVariables } from "./types";

type QueryData = FetchFeaturesListResponse["data"]["searchRetailFeatures"];

const fetchFeaturesList = pipe(
  toFetchFeaturesListVariables,
  createGqlCall<FetchFeaturesListResponse, FetchFeaturesListVariables>(
    fetchFeaturesListQuery
  )
);

export const useFeaturesList = () => {
  const [filter] = useFeaturesFilter();
  const [pagination, updatePagination] = usePagination();
  const query = useQuery<QueryData>(
    ["fetchFeaturesList", filter, pagination],
    () =>
      fetchFeaturesList(pagination, filter).then(
        ({ data }) => data.searchRetailFeatures
      ),
    {
      keepPreviousData: true,
    }
  );

  useUpdateEffect(() => {
    updatePagination({
      ...pagination,
      page: defaultPagination.page,
    });
  }, [filter, pagination.pageSize]);

  return query;
};
