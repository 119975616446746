import axios from "axios";
import { Domain } from "./types";
import { cookieService } from "./cookieService";
import { prop } from "ramda";
import { parseGqlError } from "./utils";

const apiClient = axios.create();

apiClient.interceptors.request.use(async (config) => {
  const accessToken = cookieService.get("t");

  return {
    ...config,
    headers: {
      ...config.headers,
      authorization: `Bearer ${accessToken}`,
      "access-domain": Domain.FeatureManagement,
    },
  };
});

type Response<T> = T & { errors: Array<{ message: string }> };

const gqlServiceUrl = "/api/v1/retail-graphql-gateway";
export const createGqlCall =
  <TRes, TVars = void>(query: string) =>
  (variables: TVars) =>
    apiClient
      .post<Response<TRes>>(gqlServiceUrl, {
        query,
        variables,
      })
      .then((res) => res.data)
      .then((res) => {
        if (res.errors.length) {
          throw res.errors.map(prop("message")).flatMap(parseGqlError);
        }
        return res;
      });
