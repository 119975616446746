import { message } from "antd";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { PermissionsProvider } from "./providers/PermissionsProvider";
import { Routing } from "./Routing";

export const queryClient = new QueryClient();

message.config({
  top: 70,
});
export const App = (): JSX.Element => {
  return (
    <QueryClientProvider client={queryClient}>
      <PermissionsProvider>
        <Routing />
        <ReactQueryDevtools initialIsOpen={false} />
      </PermissionsProvider>
    </QueryClientProvider>
  );
};
