import { useEffect, useMemo } from "react";
import {
  Space,
  Form,
  Button,
  Input,
  Row,
  Col,
  Skeleton,
  Switch,
  Typography,
} from "antd";
import { ImmutableTree } from "react-awesome-query-builder";
import "react-awesome-query-builder/lib/css/styles.css";
import { DefaultValues, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { config } from "@src/config";
import { formatDate } from "@utils/formatDate";
import { FeatureDetailsEntity } from "@src/api";
import { Field } from "@components/Field";
import { getValidationSchema } from "./getValidationSchema";
import { QueryBuilderField } from "@src/pages/FeatureDetails/QueryBuilderField";
import { useQueryBuilderConfig } from "@hooks/useQueryBuilderConfig";
import {
  isFeatureDetailsEqual,
  parseCondition,
  serializeCondition,
} from "@src/pages/FeatureDetails/utils";
import { CreateFeatureVM } from "../CreateFeature";

export type FeatureDetailsVM = Omit<FeatureDetailsEntity, "id">;

type EditFeatureProps = {
  mode: "edit";
  initialValues?: DefaultValues<FeatureDetailsVM>;
  onSubmit: (feature: FeatureDetailsVM) => Promise<unknown>;
};

type CreateFeatureProps = {
  mode: "create";
  initialValues?: DefaultValues<CreateFeatureVM>;
  onSubmit: (feature: CreateFeatureVM) => Promise<unknown>;
};

type FeatureDetailsProps = {
  loading?: boolean;
  enableReinitialize?: boolean;
} & (EditFeatureProps | CreateFeatureProps);

type InfoRowProps = {
  label: string;
  text: string;
  qaId: string;
};

const InfoRow = ({ label, text, qaId }: InfoRowProps) => (
  <Row>
    <Typography.Text strong>{label}:</Typography.Text>
    &nbsp;
    <Typography.Text data-qa-id={qaId}>{text}</Typography.Text>
  </Row>
);

export const FeatureDetails = ({
  mode,
  loading,
  initialValues,
  onSubmit,
}: FeatureDetailsProps) => {
  const queryBuilderConfig = useQueryBuilderConfig();
  const { handleSubmit, reset, control, watch, formState } =
    useForm<FeatureDetailsVM>({
      defaultValues: {
        ...initialValues,
        condition: parseCondition(initialValues?.condition, queryBuilderConfig),
      },
      reValidateMode: "onBlur",
      resolver: zodResolver(getValidationSchema(mode)),
    });

  useEffect(
    () =>
      reset({
        ...initialValues,
        condition: parseCondition(initialValues?.condition, queryBuilderConfig),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialValues, reset]
  );

  const current = watch();
  const isSubmitDisabled = useMemo(
    () =>
      loading ||
      !initialValues ||
      isFeatureDetailsEqual(initialValues, {
        ...current,
        condition: serializeCondition(
          current.condition as ImmutableTree,
          queryBuilderConfig
        ),
      }),
    [loading, current, initialValues, queryBuilderConfig]
  );

  return (
    <Form
      layout="vertical"
      onSubmitCapture={handleSubmit((values) =>
        onSubmit({
          ...values,
          environment: config.FEATURE_ENV,
          condition: serializeCondition(
            values.condition as ImmutableTree,
            queryBuilderConfig
          ),
        })
      )}
    >
      {loading ? (
        <Skeleton />
      ) : (
        <>
          {mode === "edit" && (
            <>
              <Space direction="vertical" style={{ marginBottom: 24 }}>
                <InfoRow
                  label="Name"
                  text={initialValues?.name ?? "-"}
                  qaId="field_name"
                />
                {initialValues != null && "createdOn" in initialValues && (
                  <>
                    <InfoRow
                      label="Created on"
                      text={
                        initialValues.createdOn
                          ? formatDate(initialValues.createdOn)
                          : "-"
                      }
                      qaId="field_created_on"
                    />
                    <InfoRow
                      label="Created by"
                      text={initialValues.createdByUser?.email ?? "-"}
                      qaId="field_created_by"
                    />
                  </>
                )}
              </Space>
              <Field
                label="Description"
                control={control}
                name="description"
                render={({ field }) => (
                  <Input.TextArea
                    placeholder="Description"
                    size="large"
                    data-qa-id="input_description"
                    {...field}
                  />
                )}
              />
              <Field
                label="Status"
                control={control}
                name="enabled"
                render={({ field }) => (
                  <Switch checked={field.value} {...field} />
                )}
              />
            </>
          )}
          {mode === "create" && (
            <>
              <Field
                label="Name"
                control={control}
                name="name"
                render={({ field }) => (
                  <Input
                    size="large"
                    placeholder="Name"
                    data-qa-id="input_name"
                    {...field}
                  />
                )}
              />
              <Field
                label="Description"
                control={control}
                name="description"
                render={({ field }) => (
                  <Input.TextArea
                    placeholder="Description"
                    size="large"
                    data-qa-id="input_description"
                    {...field}
                  />
                )}
              />
            </>
          )}
          <QueryBuilderField control={control} />
          <Row justify="end">
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                disabled={isSubmitDisabled}
                loading={formState.isSubmitting}
                data-qa-id="button_save"
              >
                {mode === "create" ? "Create" : "Save"}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Form>
  );
};
