export const CHANGE_FEATURE_STATUS = `
  mutation ChangeFeatureStatus(
    $id: UUID!,
    $isEnabled: Boolean!
  ) {
    updateRetailFeature(id: $id, feature: {
      enabled: $isEnabled
    }) { id }
  }
`;
