import { PermissionsContext } from "@src/providers/PermissionsProvider";
import { intersection } from "ramda";
import { useContext } from "react";

const permissionsMap = {
  editFeature: ["gqls://feature.management/mutation/updateretailfeature"],
  createFeature: ["gqls://feature.management/mutation/createretailfeature"],
  deleteFeature: ["gqls://feature.management/mutation/deleteretailfeature"],
  viewFeatures: [
    "gqls://feature.management/query/retailfeature",
    "gqls://feature.management/query/searchretailfeatures",
  ],
  viewFeatureChangelog: [
    "gqls://feature.management/query/searchretailfeaturechangelogs",
  ],
};

export type Permission = keyof typeof permissionsMap;

const hasNeededPermission =
  (allowed: string[]) => (needed: Permission[] | Permission) => {
    const neededPermissions = [needed]
      .flat()
      .flatMap((feature) => permissionsMap[feature]);
    return (
      intersection(neededPermissions, allowed).length ===
      neededPermissions.length
    );
  };

export const useHasPermission = () => {
  const allowedPermissions = useContext(PermissionsContext);

  return hasNeededPermission(allowedPermissions);
};
