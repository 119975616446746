export const cookieService = {
  get: (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(";").shift();
  },
  set: (name: string, value: string) => {
    document.cookie = `${name}=${value};`;
  },
};
