import { useCallback } from "react";
import { useLocation, useNavigate, generatePath } from "react-router-dom";
import pickBy from "ramda/es/pickBy";
import { routePathByPage } from "@src/constants";
import { useLang } from "./useLang";

export const useNavigateToFeaturesOverview = () => {
  const navigateTo = useNavigate();
  const lang = useLang();
  const { state: filter } = useLocation() as {
    state: null | Record<string, string>;
  };

  return useCallback(() => {
    const path = generatePath(routePathByPage.overview, { lang });
    const url =
      filter == null || !Object.keys(filter).length
        ? path
        : `${path}?${new URLSearchParams(pickBy(Boolean, filter))}`;

    navigateTo(url);
  }, [navigateTo, lang, filter]);
};
