import { FC } from "react";
import { Routes, Route, useNavigate } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { Button, Result } from "antd";
import { useHasPermission } from "@hooks/useHasPermission";
import routes from "./routes";

const AccessDenied: FC = () => {
  const navigate = useNavigate();

  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page"
      extra={
        <Button type="primary" onClick={() => navigate("/")}>
          Back Home
        </Button>
      }
    />
  );
};

export const Routing: FC = () => {
  const hasPermission = useHasPermission();

  return (
    <BrowserRouter>
      <Routes>
        {routes.map(({ path, permissions, ...routeProps }) => (
          <Route
            key={path}
            path={path}
            {...(hasPermission(permissions)
              ? routeProps
              : { element: <AccessDenied /> })}
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
};
