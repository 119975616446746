export enum Env {
  DEFAULT = "default",
  QA = "qa",
  PREPROD = "preprod",
  PROD = "prod",
}

export interface Config {
  FEATURE_ENV: Env;
}
