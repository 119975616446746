import React from "react";
import { Typography } from "antd";
import { Control, Controller } from "react-hook-form";
import { Builder, ImmutableTree, Query } from "react-awesome-query-builder";
import { useQueryBuilderConfig } from "@src/hooks";
import { FeatureDetailsVM } from "@src/pages/FeatureDetails";

interface QueryBuilderFieldProps {
  control: Control<FeatureDetailsVM>;
}

export const QueryBuilderField: React.FC<QueryBuilderFieldProps> = ({
  control,
}) => {
  const queryBuilderConfig = useQueryBuilderConfig();

  return (
    <Controller
      name="condition"
      control={control}
      render={({ field, fieldState }) => (
        <div style={{ marginBottom: "12px" }}>
          <div
            className="query-builder"
            style={{ margin: 0, width: "calc(100% + 10px)" }}
          >
            <Query
              {...queryBuilderConfig}
              value={field.value as ImmutableTree}
              onChange={(condition) => {
                field.onChange(condition);
                field.onBlur();
              }}
              renderBuilder={(props) => <Builder {...props} />}
            />
          </div>
          {fieldState.error?.message && (
            <Typography.Text type="danger">
              {fieldState.error.message}
            </Typography.Text>
          )}
        </div>
      )}
    />
  );
};
