import { Permission } from "@hooks/useHasPermission";
import { PathRouteProps } from "react-router-dom";
import { routePathByPage } from "./constants";
import { FeaturesOverview, EditFeature, CreateFeature } from "./pages";

const routes: Array<PathRouteProps & { permissions: Permission[] }> = [
  {
    path: routePathByPage.overview,
    element: <FeaturesOverview />,
    permissions: ["viewFeatures"],
  },
  {
    path: routePathByPage.edit,
    element: <EditFeature />,
    permissions: ["editFeature"],
  },
  {
    path: routePathByPage.create,
    element: <CreateFeature />,
    permissions: ["createFeature"],
  },
];

export default routes;
