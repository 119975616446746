import { reject, isNil } from "ramda";
import { config } from "@src/config";
import { Pagination, Status, FeaturesFilter } from "../../hooks";
import {
  EnvironmentFilterItem,
  Operator,
  NameFilterItem,
  StatusFilterItem,
  Filter,
  FetchFeaturesListVariables,
} from "./types";

export const getEnvFilter = (): EnvironmentFilterItem => ({
  op: Operator.Equal,
  value: config.FEATURE_ENV,
  field: "environment",
});

export const getNameFilter = (value: string): NameFilterItem => ({
  op: Operator.Like,
  field: "name",
  value: `%${value}%`,
});

export const getStatusFilter = (status: Status): StatusFilterItem | null =>
  status !== Status.All
    ? {
        op: Operator.Equal,
        field: "enabled",
        value: status === Status.Enabled,
      }
    : null;

const getFilter = ({ name, status }: FeaturesFilter): Filter => {
  const envFilter = getEnvFilter();
  const nameFilter = name ? getNameFilter(name) : null;
  const statusFilter = getStatusFilter(status);

  if (statusFilter || nameFilter) {
    return {
      op: "AND",
      value: reject(isNil, [envFilter, nameFilter, statusFilter]),
    };
  }

  return envFilter;
};

export const toFetchFeaturesListVariables = (
  pagination: Pagination,
  filter: FeaturesFilter
): FetchFeaturesListVariables => ({
  search: {
    sorts: [{ property: "updatedOn", direction: "DESC" }],
    page: pagination.page - 1,
    pageSize: pagination.pageSize,
    filter: getFilter(filter),
  },
});
