export const fetchFeaturesListQuery = `query($search: SearchProjection2Input!) {
    searchRetailFeatures(search: $search) {
        totalEntityCount
        entities {
            id
            name
            environment
            condition
            description
            enabled
        }
    }
}`;
