import { FC, ReactNode } from "react";
import { Button, Popover, Space, Spin } from "antd";
import { CheckOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { UpdateStatus } from "./types";

interface FeatureSwitchProps {
  isVisible: boolean;
  children: ReactNode;
  status: UpdateStatus | null;
  onSwitch: () => void;
  onConfirm: () => void;
  onCancel: () => void;
}

export const FeatureSwitch: FC<FeatureSwitchProps> = ({
  children,
  isVisible,
  status,
  onSwitch,
  onCancel,
  onConfirm,
}) => (
  <Popover
    title="Are you sure you want to switch this feature?"
    trigger="click"
    visible={isVisible}
    content={
      <Space
        align="center"
        style={{
          width: "100%",
          justifyContent: "center",
          minHeight: "60px",
        }}
      >
        {(() => {
          switch (status) {
            case "CONFIRMATION":
              return (
                <>
                  <Button
                    size="small"
                    type="primary"
                    onClick={onConfirm}
                    data-qa-id="switch_status_yes"
                  >
                    Yes
                  </Button>
                  <Button
                    size="small"
                    type="default"
                    onClick={onCancel}
                    data-qa-id="switch_status_no"
                  >
                    No
                  </Button>
                </>
              );
            case "IN_PROGRESS":
              return <Spin tip="Updating..." size="small" />;
            case "SUCCESS":
              return (
                <>
                  <CheckOutlined />
                  Successfully updated
                </>
              );
            case "FAILURE":
              return (
                <>
                  <ExclamationCircleOutlined />
                  Something went wrong
                </>
              );
            default:
              return null;
          }
        })()}
      </Space>
    }
    onVisibleChange={(visible) => {
      if (visible) {
        return onSwitch();
      }

      onCancel();
    }}
  >
    {children}
  </Popover>
);
