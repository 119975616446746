import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import { App } from "./App";

if (__WEBPACK_DEV_SERVER__) {
  require("antd/dist/antd.css");
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary(err) {
    // Customize the root error boundary for your microfrontend here.
    return (
      <div>
        <h2>Something went wrong.</h2>
        <pre>{JSON.stringify(err, null, 2)}</pre>
      </div>
    );
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
