export const createFeatureQuery = `mutation($feature: CreateRetailFeatureProjectionInput!) {
    createRetailFeature(feature: $feature) {
        condition
        description
        enabled
        environment
        id
        name
        updatedBy
        updatedOn
    }
}`;
